export interface SessionResponse {
  status_code: string;
  status_message: string;
  token: string;
  userid: string;
}

export interface LoginApiResponse {
  action: null;
  address: string;
  emp_number: string;
  employeeName: string;
  fname: string;
  gender: string;
  id: string;
  jobtitle: number;
  marital_status: string;
  modified_user_id: null;
  roleName: string;
  sm: string;
  status: number;
  token: null;
  user_name: string;
  user_password: null;
  user_role_id: number;
  pendingleaves: number;
  resetpass: number;
}

export const UserAccess_Dummy = {
  hiUser: {
    ADMIN: {
      Access: 1,

      'User-List': { R: 1, W: 1 },
      'User-Add': { R: 1, W: 1 },
      'User-Edit': { R: 1, W: 1 },
      'User-Remove': { R: 1, W: 1 },
      'FP-Users': { R: 1, W: 1 },

      'UserRole-List': { R: 1, W: 1 },
      'UserRole-Add': { R: 1, W: 1 },
      'UserRole-Edit': { R: 1, W: 1 },
      'UserRole-Remove': { R: 1, W: 1 },

      'Job-List': { R: 1, W: 1 },
      'Job-Add': { R: 1, W: 1 },
      'Job-Edit': { R: 1, W: 1 },
      'Job-Remove': { R: 1, W: 1 },

      'PayScale-List': { R: 1, W: 1 },
      'PayScale-Add': { R: 1, W: 1 },
      'PayScale-Edit': { R: 1, W: 1 },
      'PayScale-Remove': { R: 1, W: 1 },

      'EmploymentStatus-List': { R: 1, W: 1 },
      'EmploymentStatus-Add': { R: 1, W: 1 },
      'EmploymentStatus-Edit': { R: 1, W: 1 },
      'EmploymentStatus-Remove': { R: 1, W: 1 },

      'Cader-List': { R: 1, W: 1 },
      'Cader-Add': { R: 1, W: 1 },
      'Cader-Edit': { R: 1, W: 1 },
      'Cader-Remove': { R: 1, W: 1 },

      OrgChart: { R: 1, W: 1 },

      'Location-List': { R: 1, W: 1 },
      'Location-Add': { R: 1, W: 1 },
      'Location-Edit': { R: 1, W: 1 },
      'Location-Remove': { R: 1, W: 1 },

      OrgInfo: { R: 1, W: 1 },

      'Skill-List': { R: 1, W: 1 },
      'Skill-Add': { R: 1, W: 1 },
      'Skill-Edit': { R: 1, W: 1 },
      'Skill-Remove': { R: 1, W: 1 },

      'Education-List': { R: 1, W: 1 },
      'Education-Add': { R: 1, W: 1 },
      'Education-Edit': { R: 1, W: 1 },
      'Education-Remove': { R: 1, W: 1 },

      'Certification-List': { R: 1, W: 1 },
      'Certification-Add': { R: 1, W: 1 },
      'Certification-Edit': { R: 1, W: 1 },
      'Certification-Remove': { R: 1, W: 1 },
    },
    PIM: {
      Access: 1,
      EmployeeList: { R: 1, W: 1 },
      ManagersList: { R: 1, W: 1 },
      AddEmployee: { R: 1, W: 1 },
      BulkReporting: { R: 1, W: 1 },
      ViewProfile: { R: 1, W: 1 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
      // Secured - HR Only
      LBL: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 1, W: 1 },
      Reports: { R: 1, W: 1 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 1, W: 1 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
      HRDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 1,
    },
    REPORTS: {
      Access: 1,
      GeneralReport: { R: 1, W: 1 },
      AttendanceReport: { R: 1, W: 1 },
      AttendanceReportV1: { R: 1, W: 1 },
    },
    BULKDATA: {
      Access: 1,
      Dashboard: { R: 1, W: 1 },
      addEmployeeB: { R: 1, W: 1 },
      addEmployeePDB: { R: 1, W: 1 },
      DATA: { R: 1, W: 1 },
    },
  },
};

export const UserAccess_Data_Dummy = {
  hiUser: {
    ADMIN: {
      Access: 1,

      'User-List': { R: 1, W: 1 },
      'User-Add': { R: 1, W: 1 },
      'User-Edit': { R: 1, W: 1 },
      'User-Remove': { R: 1, W: 1 },
      'FP-Users': { R: 1, W: 1 },

      'UserRole-List': { R: 1, W: 1 },
      'UserRole-Add': { R: 1, W: 1 },
      'UserRole-Edit': { R: 1, W: 1 },
      'UserRole-Remove': { R: 1, W: 1 },

      'Job-List': { R: 1, W: 1 },
      'Job-Add': { R: 1, W: 1 },
      'Job-Edit': { R: 1, W: 1 },
      'Job-Remove': { R: 1, W: 1 },

      'PayScale-List': { R: 1, W: 1 },
      'PayScale-Add': { R: 1, W: 1 },
      'PayScale-Edit': { R: 1, W: 1 },
      'PayScale-Remove': { R: 1, W: 1 },

      'EmploymentStatus-List': { R: 1, W: 1 },
      'EmploymentStatus-Add': { R: 1, W: 1 },
      'EmploymentStatus-Edit': { R: 1, W: 1 },
      'EmploymentStatus-Remove': { R: 1, W: 1 },

      'Cader-List': { R: 1, W: 1 },
      'Cader-Add': { R: 1, W: 1 },
      'Cader-Edit': { R: 1, W: 1 },
      'Cader-Remove': { R: 1, W: 1 },

      OrgChart: { R: 1, W: 1 },

      'Location-List': { R: 1, W: 1 },
      'Location-Add': { R: 1, W: 1 },
      'Location-Edit': { R: 1, W: 1 },
      'Location-Remove': { R: 1, W: 1 },

      OrgInfo: { R: 1, W: 1 },

      'Skill-List': { R: 1, W: 1 },
      'Skill-Add': { R: 1, W: 1 },
      'Skill-Edit': { R: 1, W: 1 },
      'Skill-Remove': { R: 1, W: 1 },

      'Education-List': { R: 1, W: 1 },
      'Education-Add': { R: 1, W: 1 },
      'Education-Edit': { R: 1, W: 1 },
      'Education-Remove': { R: 1, W: 1 },

      'Certification-List': { R: 1, W: 1 },
      'Certification-Add': { R: 1, W: 1 },
      'Certification-Edit': { R: 1, W: 1 },
      'Certification-Remove': { R: 1, W: 1 },
    },
    PIM: {
      Access: 1,
      EmployeeList: { R: 1, W: 1 },
      ManagersList: { R: 1, W: 1 },
      AddEmployee: { R: 1, W: 1 },
      BulkReporting: { R: 1, W: 1 },
      ViewProfile: { R: 1, W: 1 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
      // Secured - HR Only
      LBL: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 1, W: 1 },
      Reports: { R: 1, W: 1 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 1, W: 1 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
      HRDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 1,
    },
    REPORTS: {
      Access: 1,
      GeneralReport: { R: 1, W: 1 },
      AttendanceReport: { R: 1, W: 1 },
      AttendanceReportV1: { R: 1, W: 1 },
    },
    BULKDATA: {
      Access: 1,
      Dashboard: { R: 1, W: 1 },
      addEmployeeB: { R: 1, W: 1 },
      addEmployeePDB: { R: 1, W: 1 },
      DATA: { R: 1, W: 1 },
    },
  },
  administrator: {
    ADMIN: {
      Access: 1,

      'User-List': { R: 1, W: 1 },
      'User-Add': { R: 1, W: 1 },
      'User-Edit': { R: 1, W: 1 },
      'User-Remove': { R: 1, W: 1 },
      'FP-Users': { R: 1, W: 1 },

      'UserRole-List': { R: 1, W: 1 },
      'UserRole-Add': { R: 1, W: 1 },
      'UserRole-Edit': { R: 1, W: 1 },
      'UserRole-Remove': { R: 1, W: 1 },

      'Job-List': { R: 1, W: 1 },
      'Job-Add': { R: 1, W: 1 },
      'Job-Edit': { R: 1, W: 1 },
      'Job-Remove': { R: 1, W: 1 },

      'PayScale-List': { R: 1, W: 1 },
      'PayScale-Add': { R: 1, W: 1 },
      'PayScale-Edit': { R: 1, W: 1 },
      'PayScale-Remove': { R: 1, W: 1 },

      'EmploymentStatus-List': { R: 1, W: 1 },
      'EmploymentStatus-Add': { R: 1, W: 1 },
      'EmploymentStatus-Edit': { R: 1, W: 1 },
      'EmploymentStatus-Remove': { R: 1, W: 1 },

      'Cader-List': { R: 1, W: 1 },
      'Cader-Add': { R: 1, W: 1 },
      'Cader-Edit': { R: 1, W: 1 },
      'Cader-Remove': { R: 1, W: 1 },

      OrgChart: { R: 1, W: 1 },

      'Location-List': { R: 1, W: 1 },
      'Location-Add': { R: 1, W: 1 },
      'Location-Edit': { R: 1, W: 1 },
      'Location-Remove': { R: 1, W: 1 },

      OrgInfo: { R: 1, W: 1 },

      'Skill-List': { R: 1, W: 1 },
      'Skill-Add': { R: 1, W: 1 },
      'Skill-Edit': { R: 1, W: 1 },
      'Skill-Remove': { R: 1, W: 1 },

      'Education-List': { R: 1, W: 1 },
      'Education-Add': { R: 1, W: 1 },
      'Education-Edit': { R: 1, W: 1 },
      'Education-Remove': { R: 1, W: 1 },

      'Certification-List': { R: 1, W: 1 },
      'Certification-Add': { R: 1, W: 1 },
      'Certification-Edit': { R: 1, W: 1 },
      'Certification-Remove': { R: 1, W: 1 },
    },
    PIM: {
      Access: 1,
      EmployeeList: { R: 1, W: 1 },
      ManagersList: { R: 1, W: 1 },
      AddEmployee: { R: 1, W: 1 },
      BulkReporting: { R: 1, W: 1 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 1, W: 1 },
      Reports: { R: 1, W: 1 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 1, W: 1 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
      HRDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 1,
    },
    REPORTS: {
      Access: 1,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 1, W: 1 },
      AttendanceReportV1: { R: 1, W: 1 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  admin: {
    ADMIN: {
      Access: 1,

      'User-List': { R: 1, W: 1 },
      'User-Add': { R: 0, W: 0 },
      'User-Edit': { R: 0, W: 0 },
      'User-Remove': { R: 0, W: 0 },

      'UserRole-List': { R: 1, W: 1 },
      'UserRole-Add': { R: 0, W: 0 },
      'UserRole-Edit': { R: 0, W: 0 },
      'UserRole-Remove': { R: 0, W: 0 },

      'Job-List': { R: 1, W: 1 },
      'Job-Add': { R: 1, W: 1 },
      'Job-Edit': { R: 1, W: 1 },
      'Job-Remove': { R: 1, W: 1 },

      'PayScale-List': { R: 1, W: 1 },
      'PayScale-Add': { R: 1, W: 1 },
      'PayScale-Edit': { R: 1, W: 1 },
      'PayScale-Remove': { R: 1, W: 1 },

      'EmploymentStatus-List': { R: 1, W: 1 },
      'EmploymentStatus-Add': { R: 1, W: 1 },
      'EmploymentStatus-Edit': { R: 1, W: 1 },
      'EmploymentStatus-Remove': { R: 1, W: 1 },

      'Cader-List': { R: 1, W: 1 },
      'Cader-Add': { R: 1, W: 1 },
      'Cader-Edit': { R: 1, W: 1 },
      'Cader-Remove': { R: 1, W: 1 },

      OrgChart: { R: 1, W: 1 },

      'Location-List': { R: 1, W: 1 },
      'Location-Add': { R: 1, W: 1 },
      'Location-Edit': { R: 1, W: 1 },
      'Location-Remove': { R: 1, W: 1 },

      OrgInfo: { R: 1, W: 1 },

      'Skill-List': { R: 1, W: 1 },
      'Skill-Add': { R: 1, W: 1 },
      'Skill-Edit': { R: 1, W: 1 },
      'Skill-Remove': { R: 1, W: 1 },

      'Education-List': { R: 1, W: 1 },
      'Education-Add': { R: 1, W: 1 },
      'Education-Edit': { R: 1, W: 1 },
      'Education-Remove': { R: 1, W: 1 },

      'Certification-List': { R: 1, W: 1 },
      'Certification-Add': { R: 1, W: 1 },
      'Certification-Edit': { R: 1, W: 1 },
      'Certification-Remove': { R: 1, W: 1 },
    },
    PIM: {
      Access: 1,
      EmployeeList: { R: 1, W: 1 },
      ManagersList: { R: 1, W: 1 },
      AddEmployee: { R: 1, W: 1 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 1, W: 1 },
      Reports: { R: 1, W: 1 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 0, W: 0 },
      HRDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 1,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 1, W: 1 },
      AttendanceReportV1: { R: 1, W: 1 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  hR: {
    ADMIN: {
      Access: 1,

      'User-List': { R: 1, W: 1 },
      'User-Add': { R: 1, W: 1 },
      'User-Edit': { R: 1, W: 1 },
      'User-Remove': { R: 1, W: 1 },
      'FP-Users': { R: 1, W: 1 },

      'UserRole-List': { R: 1, W: 1 },
      'UserRole-Add': { R: 1, W: 1 },
      'UserRole-Edit': { R: 1, W: 1 },
      'UserRole-Remove': { R: 1, W: 1 },

      'Job-List': { R: 1, W: 1 },
      'Job-Add': { R: 1, W: 1 },
      'Job-Edit': { R: 1, W: 1 },
      'Job-Remove': { R: 1, W: 1 },

      'PayScale-List': { R: 1, W: 1 },
      'PayScale-Add': { R: 1, W: 1 },
      'PayScale-Edit': { R: 1, W: 1 },
      'PayScale-Remove': { R: 1, W: 1 },

      'EmploymentStatus-List': { R: 1, W: 1 },
      'EmploymentStatus-Add': { R: 1, W: 1 },
      'EmploymentStatus-Edit': { R: 1, W: 1 },
      'EmploymentStatus-Remove': { R: 1, W: 1 },

      'Cader-List': { R: 1, W: 1 },
      'Cader-Add': { R: 1, W: 1 },
      'Cader-Edit': { R: 1, W: 1 },
      'Cader-Remove': { R: 1, W: 1 },

      OrgChart: { R: 1, W: 1 },

      'Location-List': { R: 1, W: 1 },
      'Location-Add': { R: 1, W: 1 },
      'Location-Edit': { R: 1, W: 1 },
      'Location-Remove': { R: 1, W: 1 },

      OrgInfo: { R: 1, W: 1 },

      'Skill-List': { R: 1, W: 1 },
      'Skill-Add': { R: 1, W: 1 },
      'Skill-Edit': { R: 1, W: 1 },
      'Skill-Remove': { R: 1, W: 1 },

      'Education-List': { R: 1, W: 1 },
      'Education-Add': { R: 1, W: 1 },
      'Education-Edit': { R: 1, W: 1 },
      'Education-Remove': { R: 1, W: 1 },

      'Certification-List': { R: 1, W: 1 },
      'Certification-Add': { R: 1, W: 1 },
      'Certification-Edit': { R: 1, W: 1 },
      'Certification-Remove': { R: 1, W: 1 },
    },
    PIM: {
      Access: 1,
      EmployeeList: { R: 1, W: 1 },
      ManagersList: { R: 1, W: 1 },
      AddEmployee: { R: 1, W: 1 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
      // Secured - HR Only
      LBL: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 1, W: 1 },
      Reports: { R: 1, W: 1 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 0, W: 0 },
      HRDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 1,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 0, W: 0 },
      AttendanceReportV1: { R: 1, W: 1 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  ess: {
    ADMIN: {
      Access: 0,

      'User-List': { R: 0, W: 0 },
      'User-Add': { R: 0, W: 0 },
      'User-Edit': { R: 0, W: 0 },
      'User-Remove': { R: 0, W: 0 },

      'UserRole-List': { R: 0, W: 0 },
      'UserRole-Add': { R: 0, W: 0 },
      'UserRole-Edit': { R: 0, W: 0 },
      'UserRole-Remove': { R: 0, W: 0 },

      'Job-List': { R: 0, W: 0 },
      'Job-Add': { R: 0, W: 0 },
      'Job-Edit': { R: 0, W: 0 },
      'Job-Remove': { R: 0, W: 0 },

      'PayScale-List': { R: 0, W: 0 },
      'PayScale-Add': { R: 0, W: 0 },
      'PayScale-Edit': { R: 0, W: 0 },
      'PayScale-Remove': { R: 0, W: 0 },

      'EmploymentStatus-List': { R: 0, W: 0 },
      'EmploymentStatus-Add': { R: 0, W: 0 },
      'EmploymentStatus-Edit': { R: 0, W: 0 },
      'EmploymentStatus-Remove': { R: 0, W: 0 },

      'Cader-List': { R: 0, W: 0 },
      'Cader-Add': { R: 0, W: 0 },
      'Cader-Edit': { R: 0, W: 0 },
      'Cader-Remove': { R: 0, W: 0 },

      OrgChart: { R: 0, W: 0 },

      'Location-List': { R: 0, W: 0 },
      'Location-Add': { R: 0, W: 0 },
      'Location-Edit': { R: 0, W: 0 },
      'Location-Remove': { R: 0, W: 0 },

      OrgInfo: { R: 0, W: 0 },

      'Skill-List': { R: 0, W: 0 },
      'Skill-Add': { R: 0, W: 0 },
      'Skill-Edit': { R: 0, W: 0 },
      'Skill-Remove': { R: 0, W: 0 },

      'Education-List': { R: 0, W: 0 },
      'Education-Add': { R: 0, W: 0 },
      'Education-Edit': { R: 0, W: 0 },
      'Education-Remove': { R: 0, W: 0 },

      'Certification-List': { R: 0, W: 0 },
      'Certification-Add': { R: 0, W: 0 },
      'Certification-Edit': { R: 0, W: 0 },
      'Certification-Remove': { R: 0, W: 0 },
    },
    PIM: {
      Access: 0,
      EmployeeList: { R: 0, W: 0 },
      ManagersList: { R: 0, W: 0 },
      AddEmployee: { R: 0, W: 0 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 0, W: 0 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 0,
      AttendanceList: { R: 0, W: 0 },
      MarkAttendance: { R: 0, W: 0 },
      Reports: { R: 0, W: 0 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 0, W: 0 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 0,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 0, W: 0 },
      AttendanceReportV1: { R: 0, W: 0 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  manager: {
    ADMIN: {
      Access: 0,

      'User-List': { R: 0, W: 0 },
      'User-Add': { R: 0, W: 0 },
      'User-Edit': { R: 0, W: 0 },
      'User-Remove': { R: 0, W: 0 },

      'UserRole-List': { R: 0, W: 0 },
      'UserRole-Add': { R: 0, W: 0 },
      'UserRole-Edit': { R: 0, W: 0 },
      'UserRole-Remove': { R: 0, W: 0 },

      'Job-List': { R: 0, W: 0 },
      'Job-Add': { R: 0, W: 0 },
      'Job-Edit': { R: 0, W: 0 },
      'Job-Remove': { R: 0, W: 0 },

      'PayScale-List': { R: 0, W: 0 },
      'PayScale-Add': { R: 0, W: 0 },
      'PayScale-Edit': { R: 0, W: 0 },
      'PayScale-Remove': { R: 0, W: 0 },

      'EmploymentStatus-List': { R: 0, W: 0 },
      'EmploymentStatus-Add': { R: 0, W: 0 },
      'EmploymentStatus-Edit': { R: 0, W: 0 },
      'EmploymentStatus-Remove': { R: 0, W: 0 },

      'Cader-List': { R: 0, W: 0 },
      'Cader-Add': { R: 0, W: 0 },
      'Cader-Edit': { R: 0, W: 0 },
      'Cader-Remove': { R: 0, W: 0 },

      OrgChart: { R: 0, W: 0 },

      'Location-List': { R: 0, W: 0 },
      'Location-Add': { R: 0, W: 0 },
      'Location-Edit': { R: 0, W: 0 },
      'Location-Remove': { R: 0, W: 0 },

      OrgInfo: { R: 0, W: 0 },

      'Skill-List': { R: 0, W: 0 },
      'Skill-Add': { R: 0, W: 0 },
      'Skill-Edit': { R: 0, W: 0 },
      'Skill-Remove': { R: 0, W: 0 },

      'Education-List': { R: 0, W: 0 },
      'Education-Add': { R: 0, W: 0 },
      'Education-Edit': { R: 0, W: 0 },
      'Education-Remove': { R: 0, W: 0 },

      'Certification-List': { R: 0, W: 0 },
      'Certification-Add': { R: 0, W: 0 },
      'Certification-Edit': { R: 0, W: 0 },
      'Certification-Remove': { R: 0, W: 0 },
    },
    PIM: {
      Access: 0,
      EmployeeList: { R: 0, W: 0 },
      ManagersList: { R: 0, W: 0 },
      AddEmployee: { R: 0, W: 0 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 0, W: 0 },
      Reports: { R: 0, W: 0 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 0,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 0, W: 0 },
      AttendanceReportV1: { R: 0, W: 0 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  how: {
    ADMIN: {
      Access: 0,

      'User-List': { R: 0, W: 0 },
      'User-Add': { R: 0, W: 0 },
      'User-Edit': { R: 0, W: 0 },
      'User-Remove': { R: 0, W: 0 },

      'UserRole-List': { R: 0, W: 0 },
      'UserRole-Add': { R: 0, W: 0 },
      'UserRole-Edit': { R: 0, W: 0 },
      'UserRole-Remove': { R: 0, W: 0 },

      'Job-List': { R: 0, W: 0 },
      'Job-Add': { R: 0, W: 0 },
      'Job-Edit': { R: 0, W: 0 },
      'Job-Remove': { R: 0, W: 0 },

      'PayScale-List': { R: 0, W: 0 },
      'PayScale-Add': { R: 0, W: 0 },
      'PayScale-Edit': { R: 0, W: 0 },
      'PayScale-Remove': { R: 0, W: 0 },

      'EmploymentStatus-List': { R: 0, W: 0 },
      'EmploymentStatus-Add': { R: 0, W: 0 },
      'EmploymentStatus-Edit': { R: 0, W: 0 },
      'EmploymentStatus-Remove': { R: 0, W: 0 },

      'Cader-List': { R: 0, W: 0 },
      'Cader-Add': { R: 0, W: 0 },
      'Cader-Edit': { R: 0, W: 0 },
      'Cader-Remove': { R: 0, W: 0 },

      OrgChart: { R: 0, W: 0 },

      'Location-List': { R: 0, W: 0 },
      'Location-Add': { R: 0, W: 0 },
      'Location-Edit': { R: 0, W: 0 },
      'Location-Remove': { R: 0, W: 0 },

      OrgInfo: { R: 0, W: 0 },

      'Skill-List': { R: 0, W: 0 },
      'Skill-Add': { R: 0, W: 0 },
      'Skill-Edit': { R: 0, W: 0 },
      'Skill-Remove': { R: 0, W: 0 },

      'Education-List': { R: 0, W: 0 },
      'Education-Add': { R: 0, W: 0 },
      'Education-Edit': { R: 0, W: 0 },
      'Education-Remove': { R: 0, W: 0 },

      'Certification-List': { R: 0, W: 0 },
      'Certification-Add': { R: 0, W: 0 },
      'Certification-Edit': { R: 0, W: 0 },
      'Certification-Remove': { R: 0, W: 0 },
    },
    PIM: {
      Access: 0,
      EmployeeList: { R: 0, W: 0 },
      ManagersList: { R: 0, W: 0 },
      AddEmployee: { R: 0, W: 0 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 0, W: 0 },
      Reports: { R: 0, W: 0 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 0,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 0, W: 0 },
      AttendanceReportV1: { R: 0, W: 0 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  hod: {
    ADMIN: {
      Access: 0,

      'User-List': { R: 0, W: 0 },
      'User-Add': { R: 0, W: 0 },
      'User-Edit': { R: 0, W: 0 },
      'User-Remove': { R: 0, W: 0 },

      'UserRole-List': { R: 0, W: 0 },
      'UserRole-Add': { R: 0, W: 0 },
      'UserRole-Edit': { R: 0, W: 0 },
      'UserRole-Remove': { R: 0, W: 0 },

      'Job-List': { R: 0, W: 0 },
      'Job-Add': { R: 0, W: 0 },
      'Job-Edit': { R: 0, W: 0 },
      'Job-Remove': { R: 0, W: 0 },

      'PayScale-List': { R: 0, W: 0 },
      'PayScale-Add': { R: 0, W: 0 },
      'PayScale-Edit': { R: 0, W: 0 },
      'PayScale-Remove': { R: 0, W: 0 },

      'EmploymentStatus-List': { R: 0, W: 0 },
      'EmploymentStatus-Add': { R: 0, W: 0 },
      'EmploymentStatus-Edit': { R: 0, W: 0 },
      'EmploymentStatus-Remove': { R: 0, W: 0 },

      'Cader-List': { R: 0, W: 0 },
      'Cader-Add': { R: 0, W: 0 },
      'Cader-Edit': { R: 0, W: 0 },
      'Cader-Remove': { R: 0, W: 0 },

      OrgChart: { R: 0, W: 0 },

      'Location-List': { R: 0, W: 0 },
      'Location-Add': { R: 0, W: 0 },
      'Location-Edit': { R: 0, W: 0 },
      'Location-Remove': { R: 0, W: 0 },

      OrgInfo: { R: 0, W: 0 },

      'Skill-List': { R: 0, W: 0 },
      'Skill-Add': { R: 0, W: 0 },
      'Skill-Edit': { R: 0, W: 0 },
      'Skill-Remove': { R: 0, W: 0 },

      'Education-List': { R: 0, W: 0 },
      'Education-Add': { R: 0, W: 0 },
      'Education-Edit': { R: 0, W: 0 },
      'Education-Remove': { R: 0, W: 0 },

      'Certification-List': { R: 0, W: 0 },
      'Certification-Add': { R: 0, W: 0 },
      'Certification-Edit': { R: 0, W: 0 },
      'Certification-Remove': { R: 0, W: 0 },
    },
    PIM: {
      Access: 0,
      EmployeeList: { R: 0, W: 0 },
      ManagersList: { R: 0, W: 0 },
      AddEmployee: { R: 0, W: 0 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 0, W: 0 },
      Reports: { R: 0, W: 0 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 0,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 0, W: 0 },
      AttendanceReportV1: { R: 0, W: 0 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
  ceo: {
    ADMIN: {
      Access: 0,

      'User-List': { R: 0, W: 0 },
      'User-Add': { R: 0, W: 0 },
      'User-Edit': { R: 0, W: 0 },
      'User-Remove': { R: 0, W: 0 },

      'UserRole-List': { R: 0, W: 0 },
      'UserRole-Add': { R: 0, W: 0 },
      'UserRole-Edit': { R: 0, W: 0 },
      'UserRole-Remove': { R: 0, W: 0 },

      'Job-List': { R: 0, W: 0 },
      'Job-Add': { R: 0, W: 0 },
      'Job-Edit': { R: 0, W: 0 },
      'Job-Remove': { R: 0, W: 0 },

      'PayScale-List': { R: 0, W: 0 },
      'PayScale-Add': { R: 0, W: 0 },
      'PayScale-Edit': { R: 0, W: 0 },
      'PayScale-Remove': { R: 0, W: 0 },

      'EmploymentStatus-List': { R: 0, W: 0 },
      'EmploymentStatus-Add': { R: 0, W: 0 },
      'EmploymentStatus-Edit': { R: 0, W: 0 },
      'EmploymentStatus-Remove': { R: 0, W: 0 },

      'Cader-List': { R: 0, W: 0 },
      'Cader-Add': { R: 0, W: 0 },
      'Cader-Edit': { R: 0, W: 0 },
      'Cader-Remove': { R: 0, W: 0 },

      OrgChart: { R: 0, W: 0 },

      'Location-List': { R: 0, W: 0 },
      'Location-Add': { R: 0, W: 0 },
      'Location-Edit': { R: 0, W: 0 },
      'Location-Remove': { R: 0, W: 0 },

      OrgInfo: { R: 0, W: 0 },

      'Skill-List': { R: 0, W: 0 },
      'Skill-Add': { R: 0, W: 0 },
      'Skill-Edit': { R: 0, W: 0 },
      'Skill-Remove': { R: 0, W: 0 },

      'Education-List': { R: 0, W: 0 },
      'Education-Add': { R: 0, W: 0 },
      'Education-Edit': { R: 0, W: 0 },
      'Education-Remove': { R: 0, W: 0 },

      'Certification-List': { R: 0, W: 0 },
      'Certification-Add': { R: 0, W: 0 },
      'Certification-Edit': { R: 0, W: 0 },
      'Certification-Remove': { R: 0, W: 0 },
    },
    PIM: {
      Access: 0,
      EmployeeList: { R: 0, W: 0 },
      ManagersList: { R: 0, W: 0 },
      AddEmployee: { R: 0, W: 0 },
      BulkReporting: { R: 0, W: 0 },
    },
    LEAVE: {
      Access: 1,
      ManagerLists: { R: 1, W: 1 },
    },
    IS: {
      Access: 1,
      isDashboard: { R: 1, W: 1 },
    },
    ATTENDANCE: {
      Access: 1,
      AttendanceList: { R: 1, W: 1 },
      MarkAttendance: { R: 0, W: 0 },
      Reports: { R: 0, W: 0 },
    },
    DASHBOARD: {
      Access: 1,
      HBD: { R: 0, W: 0 },
      EmployeeDashboard: { R: 1, W: 1 },
      ManagerDashboard: { R: 1, W: 1 },
    },
    SETTINGS: {
      Access: 0,
    },
    REPORTS: {
      Access: 0,
      GeneralReport: { R: 0, W: 0 },
      AttendanceReport: { R: 0, W: 0 },
      AttendanceReportV1: { R: 0, W: 0 },
    },
    BULKDATA: {
      Access: 0,
      Dashboard: { R: 0, W: 0 },
      addEmployeeB: { R: 0, W: 0 },
      addEmployeePDB: { R: 0, W: 0 },
      DATA: { R: 0, W: 0 },
    },
  },
};
